@import "./grid.scss";
@import "./utility.scss";
@import "./dev-icons.scss";
@import "./custom-icons.scss";
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&display=swap");
@font-face {
  font-family: skyer;
  font-display: swap;
  src: url(../fonts/Skyer.otf);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $dark1;
  color: $white;
  overflow: hidden;
  // cursor: none;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  padding: 2rem;
  background-image: radial-gradient(#555 0.5px, $dark1 0.5px);
  background-size: 10px 10px;
  @media (max-width: $sm) {
    padding: 12px;
  }
  ::selection {
    background: #999;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: Skyer;
    margin: 0 0;
    letter-spacing: 1.5px;
    color: $white;
  }
  p,
  span,
  a,
  li {
    font-family: "Fira Code", monospace;
    color: $white;
  }
  a {
    text-decoration: none;
    // cursor: none;
    letter-spacing: 2px;
  }
  #root {
    // .pointer {
    //   cursor: none;
    // }
    .cursor {
      width: 30px;
      height: 30px;
      border: 2px solid $white;
      z-index: 999;
      border-radius: 50%;
      position: absolute;
      transition: 0.15s all ease-out;
      pointer-events: none;
      will-change: transform, opacity;
      -webkit-font-smoothing: antialiased;
      &::after {
        content: "";
        position: absolute;
        width: 2px;
        height: 2px;
        background-color: $white;
        left: 13.7px;
        top: 13.5px;
      }
      .rightArrow {
        content: url(../images/rightarrow.svg);
        width: 32px;
        height: 20px;
        position: absolute;
        top: 4px;
        left: 13px;
        opacity: 0;
        transition: 0.3s all ease-in-out;
      }
      &.showArrow {
        border-style: dashed;
        .rightArrow {
          opacity: 1;
        }
        &::after {
          opacity: 0;
        }
      }
      &.expand {
        z-index: 999;
        background-color: $white1;
        animation: scaleToggle 0.3s forwards;
      }
    }
    .container {
      position: relative;
      overflow: hidden;
      max-width: $lg;
      margin: 0 auto;
      &.isMobile {
        .fadeInDown,
        .fadeInUp,
        .fadeOutUp,
        .fadeOutDown {
          filter: none !important;
        }
        .ScrollbarsCustom-Track {
          display: none !important;
        }
      }
      .fallBackDiv {
        height: $containerHeight;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background-color: $dark1;
        background-image: radial-gradient(#555 0.5px, $dark1 0.5px);
        background-size: 10px 10px;
        span {
          display: block;
        }
        img {
          filter: grayscale(1);
          width: 400px;
          @media (max-width: $sm) {
            width: 300px;
          }
        }
        h5 {
          text-align: center;
        }
        h4 {
          text-align: center;
        }
      }
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        opacity: 0;
        height: $navHeight;
        margin: 0 auto 1rem auto;
        @media (max-width: $sm) {
          padding: 0 1rem;
          margin-bottom: 0;
        }
        .logo {
          overflow: hidden;
          z-index: 2;
          img {
            width: 46px;
            height: 50px;
            margin-right: 5px;
            @media (max-width: $sm) {
              width: 40px;
              height: 44px;
            }
          }
        }
        ul {
          text-align: right;
          @media (max-width: $sm) {
            display: none;
          }
          li {
            display: inline-block;
            list-style: none;
            &:not(:last-child) {
              margin-right: 1.5rem;
            }
            a {
              font-weight: 600;
              letter-spacing: 2px;
              font-family: Skyer;
              font-size: 18px;
              position: relative;
              &.active {
                &:before {
                  content: "";
                  position: absolute;
                  width: 110%;
                  height: 1px;
                  top: 50%;
                  left: 50%;
                  transform: translateX(-50%);
                  background: $grey;
                }
              }
            }
          }
        }
        .hamburgerMenu {
          max-width: 1rem;
          display: none;
          position: relative;
          @media (max-width: $sm) {
            display: block;
            margin-top: -10px;
          }
          .line {
            width: 1rem;
            height: 2px;
            border-radius: 10px;
            background-color: $white;
            position: absolute;
            right: 0;
            transition: 0.3s all ease-in-out;
            &:nth-child(1) {
              top: 0;
            }
            &:nth-child(2) {
              top: 8px;
            }
          }
          &.crossIcon {
            .line:nth-child(1) {
              top: 8.5px;
              transform: rotate(45deg);
            }
            .line:nth-child(2) {
              bottom: 7.5px;
              transform: rotate(-45deg);
            }
          }
        }
      }
      .welcomeMessage {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        h1 {
          font-size: 40px;
          text-align: center;
          letter-spacing: 6px;
          @media (max-width: $sm) {
            font-size: calc(2vw + 2vh + 4px);
          }
        }
        h2 {
          text-align: center;
          font-size: 32px;
          @media (max-width: $sm) {
            font-size: calc(1.5vw + 1.5vh + 2px);
          }
        }
        button {
          margin-top: 32px;
        }
      }
      .midContent {
        box-sizing: border-box;
        height: $containerHeight;
        position: relative;
        // max-width: $lg;
        // margin: 0 auto;
        overflow: hidden;
        transition: 0.3s all ease-in-out;
        @media (max-width: $sm) {
          height: $mobileContainerHeight;
          padding: 0 20px;
        }
        @media (max-width: $xs) {
          padding: 0 !important;
        }
        .ScrollbarsCustom-Track {
          &.ScrollbarsCustom-TrackY {
            top: 75px !important;
            width: 3px !important;
            opacity: 0.4 !important;
            transition: 0.1s all ease-in-out;
            &:hover {
              width: 6px !important;
            }
            > div {
              background-color: $white !important;
            }
            .ScrollbarsCustom-Thumb {
              cursor: none !important;
            }
          }
          &.ScrollbarsCustom-TrackX {
            background-color: transparent !important;
            > div {
              background-color: transparent !important;
            }
          }
        }
        .title {
          margin: 2rem 0 1rem 0;
          position: relative;
          .headingTitle {
            font-size: 32px;
            letter-spacing: 4px;
            margin-left: 3rem;
            text-transform: capitalize;
            @media (max-width: $sm) {
              font-size: 26px;
              margin-left: 1.5rem;
            }
          }
          .circle {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background-color: $white;
            position: absolute;
            top: 26px;
            left: 8px;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            &:before,
            &:after {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border-radius: 50%;
              border: 0.5px solid $white;
            }
            &:before {
              -webkit-animation: ripple 2s linear infinite;
              animation: ripple 2s linear infinite;
            }
            @media (max-width: $sm) {
              display: none;
            }
          }
          .line {
            width: 1rem;
            height: 1px;
            background: $white;
            position: absolute;
            top: 26px;
            left: 20px;
            @media (max-width: $sm) {
              top: 20px;
              left: 0px;
              width: 1rem;
            }
          }
        }
        .gridDisplay {
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
          @media (max-width: $sm) {
            grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
          }
          &.grid4 {
            grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
            @media (max-width: $sm) {
              grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
            }
          }
          .gridTitle {
            text-align: center;
            font-size: 26px;
            margin: 2rem 0;
            letter-spacing: 2px;
            transition: 0.3s all ease-in-out;
            &.pointer {
              cursor: pointer;
            }
            @media (max-width: $sm) {
              font-size: 22px;
              margin: 1.5rem 0;
            }
            &.m-1 {
              margin: 1rem 0;
            }
            &.small {
              p {
                font-size: 16px;
                margin: 0.5rem 0;
              }
              img {
                width: 26px;
                height: 26px;
              }
              i {
                font-size: 32px;
                @media (max-width: $sm) {
                  font-size: 26px;
                }
              }
            }
            &:hover:not(.small) {
              //transform: scale(1.05);
            }
          }
        }
        .description {
          color: $white;
          position: relative;
          margin: 0.5rem 0;
          font-size: 17px;
          letter-spacing: 1.5px;
          line-height: 2rem;
          box-sizing: border-box;
          b {
            font-size: 18px;
          }
          @media (max-width: $sm) {
            font-size: 16px;
          }
        }
        .subHeading {
          margin: 2rem 0;
          display: flex;
          &.mAutoMobile {
            @media (max-width: 393px) {
              justify-content: center;
            }
          }
          .lineIcon {
            margin-top: 0.6rem;
            .circle {
              background: $white;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              margin-top: 3px;
              @media (max-width: $sm) {
                margin-top: 0;
              }
            }
          }
          .headContent {
            margin-left: 1rem;
            @media (max-width: $sm) {
              margin-left: 10px;
            }
            a,
            h4 {
              font-size: 24px;
              letter-spacing: 3px;
              font-weight: 500;
              text-transform: capitalize;
              @media (max-width: $sm) {
                font-size: 18px;
              }
            }
            p {
              color: $white;
              font-size: 16px;
              line-height: 2rem;
              box-sizing: border-box;
              margin: 0.5rem 0;
              @media (max-width: $sm) {
                font-size: 15px;
              }
            }
            h3 {
              font-size: 20px;
              letter-spacing: 3px;
              font-family: monospace;
              text-transform: capitalize;
              @media (max-width: $sm) {
                font-size: 17px;
              }
            }
          }
          &.flexedContent {
            display: flex;
            justify-content: space-between;
            align-items: center;
            a {
              font-size: 26px;
              font-weight: 600;
              text-transform: capitalize;
              @media (max-width: $sm) {
                font-size: 20px;
                > .external-icon {
                  display: none;
                }
              }
            }
            .back-icon {
              margin-left: -4px;
            }
          }
        }
        .mainContent {
          padding: 0 1.5rem 0 2rem;
          @media (max-width: $sm) {
            padding: 0 0.5rem;
          }
        }
      }
      .footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        opacity: 0;
        margin-top: 1rem;
        height: $footerHeight;
        @media (max-width: $sm) {
          justify-content: space-evenly;
        }
        ul {
          padding: 0;
          li {
            display: inline-block;
            list-style: none;
            &:not(:last-child) {
              margin-right: 4rem;
              @media (max-width: $sm) {
                margin-right: 4rem;
              }
            }
            a {
              font-size: 14px;
              letter-spacing: 2px;
            }
          }
        }
      }
      .mobileMenu {
        background-color: transparent;
        position: absolute;
        top: 60px;
        right: 0;
        opacity: 0;
        z-index: -1;
        width: 100%;
        height: $mobileMenuHeight;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        &.show {
          transition: opacity 0.3s all ease-in-out;
        }
        a {
          font-size: 24px;
          text-align: center;
          margin: 3rem 0;
          position: relative;
          &.active {
            &:before {
              content: "";
              position: absolute;
              width: 100px;
              height: 1px;
              top: 55%;
              left: 50%;
              transform: translateX(-50%);
              background: $grey;
            }
          }
        }
      }
    }
  }
}
